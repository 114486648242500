<template>
  <div>
    <el-card class="box-card">
      <div class="qy_tit">
        <img
          style="width: 10px; height: 20px"
          src="@/assets/images/normal_u10.svg"
          alt=""
        />
        <div>签约详情</div>
      </div>
      <div class="qy_Box" v-if="flag == 1">
        <div class="qy_Boxli">
          <div class="lis">
            <div class="lis_one">合同标题：</div>
            <div class="lis_two">
              {{ signdetail.customerName + "的签约合同" }}
            </div>
          </div>
          <div class="lis">
            <div class="lis_one">签署场景：</div>
            <div class="lis_two">农户签约</div>
          </div>
        </div>
        <div class="qy_Boxli">
          <div class="lis">
            <div class="lis_one">发起时间：</div>
            <div class="lis_two">{{ signdetail.creationtime }}</div>
          </div>
          <div class="lis">
            <div class="lis_one">签署时间：</div>
            <div class="lis_two">{{ signdetail.agencytime }}</div>
          </div>
        </div>
        <div class="qy_Boxli">
          <div class="lis">
            <div class="lis_one">合同发送状态：</div>
            <div class="lis_two">
              {{ signdetail.contractSending == 0 ? "未发送" : "已发送" }}
            </div>
          </div>
          <div class="lis">
            <div class="lis_one">合同编号：</div>
            <div class="lis_two">{{ signdetail.id }}</div>
          </div>
        </div>
        <div class="qy_Boxli">
          <div class="lis">
            <div class="lis_one">合同附件：</div>
            <div
              class="lis_two"
              style="display: flex; align-items: center"
              @click="handlePDF"
              v-if="this.pdfImg"
            >
              <img
                style="width: 90px; height: 100px"
                src="@/assets/images/PDF.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              @click="sendContract"
              style="margin-right: 30px"
              >发送合同</el-button
            >
          </el-row>
        </div>
      </div>
      <div v-if="flag == 0">暂未签约信息</div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    signdetail: {
      typeof: Object,
      default: () => {},
    },
    pdfImg: {
      typeof: String,
      default: "",
    },
    customerCode: {
      typeof: String,
      default: "",
    },
  },
  data() {
    return {
      flag: "",
    };
  },
  created() {
    this.getSendContract();
  },
  methods: {
    handlePDF() {
      window.open(this.pdfImg);
    },
    async getSendContract() {
      const res = await this.$http.post("/sMSLogs/sendContract", {
        customerCode: this.customerCode,
      });
      if (res.data.code == 200) {
        this.flag = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    sendContract() {
      this.$confirm.success("确定发送合同吗？").then(async () => {
        const res = this.$http.post("/sMSLogs/sendContractSMS", {
          customerCode: this.customerCode,
        });
        if(res.data.code == 200 && res.data.data == true) {
          this.$message.success("发送成功");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .box-card {
  .el-card__body {
    min-height: 200px;
    padding: 10px;
  }
}
</style>
